import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Link from 'next/link';
import { logout } from '../../services/security.service';
import { useAuthentication } from '../../hooks/useAuthentication';
import { Crown } from '../Icon';
import { isBrowser } from '../../helpers/browser';
import Popover from '../Popover';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PlayCircleOutlineOutlinedIcon from '@material-ui/icons/PlayCircleOutlineOutlined';
import WidgetsOutlinedIcon from '@material-ui/icons/WidgetsOutlined';
import FolderOutlinedIcon from '@material-ui/icons/FolderOutlined';
import TheatersOutlinedIcon from '@material-ui/icons/TheatersOutlined';
import { useRouter } from 'next/router';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';

const useStyles = makeStyles(theme => ({
  menuItemLink: {
    textDecoration: 'unset !important',
    color: 'unset',
    '& .MuiListItem-button:hover': {
      backgroundColor: '#FED102'
    }
  },
  menuItem: {
    padding: '6px 16px',
    display: 'flex',
    justifyContent: 'flex-start',
    // textTransform: 'capitalize',
    fontSize: 14,
    // fontWeight: 300,
    lineHeight: '19px',
    fontFamily: 'Open sans, sans-serif',
    color: '#414042',
    // letterSpacing: '1.4px',
    '&:hover': {
      backgroundColor: '#f9f9f9'
    },
    marginBottom: 4,
    // paddingTop: 0,
    // paddingBottom: 0
    '& span': {
      marginLeft: 10
    }
  },
  userInfo: {
    fontSize: 12,
    marginLeft: 6,
    textTransform: 'initial'
  },
  avatar: {
    fontSize: 12,
    width: '28px',
    height: '28px',
    textTransform: 'uppercase',
    fontWeight: 700
  },
  userMenuButton: {
    padding: '0',
    borderRadius: '50%',
    minWidth: 0,
    '&:hover': {
      backgroundColor: 'transparent'
    },
    '& svg': {
      left: 'unset',
      right: 14,
      top: 18
    }
  },
  list: {
    // paddingBottom: 20,
    '@media only screen and (max-width: 768px)': {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center'
    }
  },
  avatarIn: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '16px 16px 0px',
    marginBottom: 4,
    '@media only screen and (max-width: 768px)': {
      justifyContent: 'center'
    },
    '& svg': {
      left: 'unset',
      right: 27,
      top: 32
    }
  },
  paper: {
    boxShadow: '0px 3px 18px #00000029',
    borderRadius: 12,
    padding: 0
  },
  premiumIcon: {
    position: 'absolute',
    top: 32,
    left: -6,
    svg: {
      width: 25,
      height: 25,
      top: 18,
      left: 'unset',
      right: 14
    }
  }
}));

const UserMenu = ({ user }) => {
  const classes = useStyles();
  const { dispatch, plan } = useAuthentication();
  const router = useRouter();
  const [open, setOpen] = React.useState(false);

  const handleClick = event => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNavTutorial = () => {
    setOpen(false);
    window.open('https://support.woxo.tech/en', '_blank');
  };

  const handleLogout = () => {
    logout().then(() => {
      dispatch({
        type: 'LOGOUT',
        payload: null
      });
      router.replace('/');
    });
  };

  return (
    <div className="user-menu">
      <Popover
        open={open}
        onClose={() => setOpen(false)}
        content={
          <Paper className={classes.paper}>
            <MenuList id="menu-list-grow" className={classes.list}>
              <Link href="/dashboard?tab=settings">
                <MenuItem onClick={handleClose} className={classes.menuItem}>
                  <SettingsOutlinedIcon />
                  <span>My Account</span>
                </MenuItem>
              </Link>
              <Link href="/dashboard?tab=videos">
                <MenuItem onClick={handleClose} className={classes.menuItem}>
                  <PlayCircleOutlineOutlinedIcon />
                  <span>My Videos</span>
                </MenuItem>
              </Link>
              <Link href="/dashboard?tab=widgets">
                <MenuItem onClick={handleClose} className={classes.menuItem}>
                  <WidgetsOutlinedIcon />
                  <span>My Widgets</span>
                </MenuItem>
              </Link>
              <Link href="/dashboard?tab=assets">
                <MenuItem onClick={handleClose} className={classes.menuItem}>
                  <FolderOutlinedIcon />
                  <span>My Assets</span>
                </MenuItem>
              </Link>
              <MenuItem onClick={handleNavTutorial} className={classes.menuItem}>
                <TheatersOutlinedIcon />
                <span>Tutorials</span>
              </MenuItem>
              <MenuItem onClick={handleLogout} className={classes.menuItem}>
                <ExitToAppIcon />
                <span>Logout</span>
              </MenuItem>
            </MenuList>
          </Paper>
        }
      >
        <Button onClick={handleClick} color="inherit" className={classes.userMenuButton}>
          {user && user.picture && (
            <Avatar className={classes.avatar} alt={user.username} src={user.picture} />
          )}
          {user && !user.picture && (
            <Avatar className={classes.avatar}>{user.username.substring(0, 2)}</Avatar>
          )}
          {plan && <Crown className={classes.premiumIcon} />}
        </Button>
      </Popover>
    </div>
  );
};

UserMenu.propTypes = {
  user: PropTypes.objectOf(PropTypes.any)
};

UserMenu.defaultProps = {
  user: null
};

export default UserMenu;
